import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { toast } from '@lionstep/ui';

// Api
import { pythonAxios, layer1Axios } from '../api/http';
import {
  postJobMailingPermissionApi,
  deleteJobMailingPermissionApi,
  postUserToJobApi,
  deleteUserFromJobApi,
  getDashboardJobsApi,
  sendNewJobCollaboratorEmailApi,
} from '../api/job.api';

const JOB_QUERY_KEYS = {
  jobCompact: 'jobCompact',
  dashboardJobs: 'dashboardJobs',
  createJob: 'createJob',
  updateJobPatch: 'updateJobPatch',
  extraInfo: 'extraInfo',
  question: 'question',
  screeningQuestions: 'screeningQuestions',
  jobVersions: 'jobVersions',
  addUserToJob: 'addUserToJob',
  deleteUserFromJob: 'deleteUserFromJob',
  addJobMailingPermissions: 'addJobMailingPermissions',
  deleteJobMailingPermissions: 'deleteJobMailingPermissions',
  jobTitles: 'jobTitles',
  aiTitleSuggestion: 'aiTitleSuggestion',
};

// === JOB START === //
const getJobCompact = ({ id }) =>
  pythonAxios({
    method: 'GET',
    url: `/jobs/${id}/compact`,
  }).then(({ data }) => data);

export const useJobCompactQuery = ({ id, options = {} }) =>
  useQuery([JOB_QUERY_KEYS.jobCompact, id], () => getJobCompact({ id }), {
    ...options,
    staleTime: 600000,
  });

// Create Job
const postJob = ({ data: jobData }) =>
  pythonAxios({
    method: 'POST',
    url: '/jobs',
    data: jobData,
  }).then(({ data }) => data);

export const useJobCreateMutation = ({ options = {} } = {}) =>
  useMutation([JOB_QUERY_KEYS.createJob], postJob, { ...options });

const postPatch = ({ id, data: jobData }) =>
  pythonAxios({
    method: 'PATCH',
    url: `/jobs/${id}`,
    data: jobData,
  }).then(({ data }) => data);

// TODO: we can update job using PATCH and PUT, at one point this
// should be the done with a same method. At that moment, please
// remove method name from hook name
export const useJobUpdatePatchMutation = ({ options = {} } = {}) =>
  useMutation([JOB_QUERY_KEYS.updateJobPatch], postPatch, { ...options });

export const useDashboardJobs = ({
  onSuccess,
  onError,
  params,
  options = {},
} = {}) =>
  useQuery(
    [JOB_QUERY_KEYS.dashboardJobs, params],
    () => getDashboardJobsApi({ onSuccess, onError, params }),
    {
      staleTime: 300000, // 5 minutes
      placeholderData: {
        result: [],
        count: 0,
      },
      ...options,
    },
  );
// === JOB START === //

// === EXTRA INFO START === //
// Update Extra info
const postExtraInfo = ({ id, data }) =>
  pythonAxios({
    method: 'POST',
    url: `/jobs/${id}/extra_info`,
    data: {
      extra_info: data,
    },
  });

export const useJobExtraInfoMutation = ({ options = {} } = {}) =>
  useMutation([JOB_QUERY_KEYS.extraInfo], postExtraInfo, { ...options });
// === EXTRA INFO START === //

// === VERSIONS START === //
// Create job version
const postJobVersion = ({ id }) =>
  pythonAxios({
    method: 'POST',
    url: `/jobs/${id}/versions`,
    data: {},
  });

export const useJobCreateVersionsMutation = ({ options = {} } = {}) =>
  useMutation([JOB_QUERY_KEYS.jobVersions], postJobVersion, { ...options });
// === QUESTIONS EMD === //

// === QUESTIONS START === //
// Get Screening questiosn
const getScreeningQuestions = ({ id }) =>
  layer1Axios({
    method: 'GET',
    url: `/v3/main/jobs/${id}/screening_questions`,
  }).then(({ data }) => data);

export const useScreeningQuestionsQuery = ({ id, options = {} }) =>
  useQuery(
    [JOB_QUERY_KEYS.screeningQuestions, id],
    () => getScreeningQuestions({ id }),
    {
      ...options,
    },
  );

// Get Questions
const getQuestions = ({ id, questionType }) =>
  pythonAxios({
    method: 'GET',
    url: `/jobs/${id}/questions/${questionType}`,
  }).then(({ data }) => data.questions);

export const useQuestionsQuery = ({ id, questionType, options = {} }) =>
  useQuery(
    [JOB_QUERY_KEYS.question, id, questionType],
    () => getQuestions({ id, questionType }),
    {
      ...options,
    },
  );

// Update Questions
const postQuestions = ({ id, questionType, data: questionsData }) =>
  pythonAxios({
    method: 'POST',
    url: `/jobs/${id}/questions/${questionType}`,
    data: questionsData,
  }).then(({ data }) => data.questions);

export const useQuestionsMutation = ({ id, questionType, options = {} }) =>
  useMutation(
    [JOB_QUERY_KEYS.question, id, questionType],
    ({ id: jobId, data }) =>
      postQuestions({ id: jobId || id, questionType, data }),
    { ...options },
  );
// === QUESTIONS END === //

// === JOB USERS START === //
export const useAddUserToJobMutation = ({
  onSuccess,
  onError,
  options = {},
} = {}) => {
  const queryClient = useQueryClient();

  return useMutation([JOB_QUERY_KEYS.addUserToJob], postUserToJobApi, {
    onSuccess: (data, variables) => {
      if (onSuccess && typeof onSuccess === 'function') {
        onSuccess(data);
      }

      const dashboardQueries = queryClient.getQueriesData([
        JOB_QUERY_KEYS.dashboardJobs,
      ]);

      dashboardQueries.forEach(([queryKey, oldData]) => {
        const newResultData = oldData.result.map((job) => {
          if (job.id === variables.jobId) {
            return {
              ...job,
              users: [...job.users, variables.userId],
            };
          }

          return job;
        });

        queryClient.setQueryData(queryKey, {
          ...oldData,
          result: newResultData,
        });
      });
    },
    onError: (error) => {
      if (onError && typeof onError === 'function') {
        onError(error);
      }
    },
    ...options,
  });
};

export const useDeleteUserFromJobMutation = ({
  onSuccess,
  onError,
  options = {},
} = {}) => {
  const queryClient = useQueryClient();

  return useMutation([JOB_QUERY_KEYS.deleteUserFromJob], deleteUserFromJobApi, {
    onSuccess: (data, variables) => {
      if (onSuccess && typeof onSuccess === 'function') {
        onSuccess(data);
      }

      const dashboardQueries = queryClient.getQueriesData([
        JOB_QUERY_KEYS.dashboardJobs,
      ]);

      dashboardQueries.forEach(([queryKey, oldData]) => {
        const newResultData = oldData.result.map((job) => {
          if (job.id === variables.jobId) {
            return {
              ...job,
              users: job.users.filter((userId) => userId !== variables.userId),
            };
          }

          return job;
        });

        queryClient.setQueryData(queryKey, {
          ...oldData,
          result: newResultData,
        });
      });
    },
    onError: (error) => {
      if (onError && typeof onError === 'function') {
        onError(error);
      }
    },
    ...options,
  });
};

export const useSendUserNewJobEmailMutation = ({
  onSuccess,
  onError,
  options = {},
} = {}) =>
  useMutation(
    [JOB_QUERY_KEYS.sendUserNewJobEmail],
    ({ user, jobTitle }) => {
      const data = {
        jobTitle,
        email: user.email,
        language: user.language,
        firstName: user.first_name,
        lastName: user.last_name,
      };

      return sendNewJobCollaboratorEmailApi({ data });
    },
    {
      ...options,
      onSuccess: (data) => {
        if (onSuccess && typeof onSuccess === 'function') {
          onSuccess(data);
        }
      },
      onError: (error) => {
        if (onError && typeof onError === 'function') {
          onError(error);
        }
        toast.error('Error sending email to user', {
          id: JOB_QUERY_KEYS.sendUserNewJobEmail,
          statusCode: error?.response?.status,
        });
      },
    },
  );
// === JOB USERS END === //

// === JOB MAILING PERMISSIONS START === //
export const useAddJobMailingPermissionsMutation = ({
  onSuccess,
  onError,
  options = {},
} = {}) =>
  useMutation(
    [JOB_QUERY_KEYS.addJobMailingPermissions],
    ({ jobId, data }) => postJobMailingPermissionApi({ jobId, data }),
    {
      onSuccess: (data) => {
        if (onSuccess && typeof onSuccess === 'function') {
          onSuccess(data);
        }
      },
      onError: (error) => {
        if (onError && typeof onError === 'function') {
          onError(error);
        }
      },
      ...options,
    },
  );

export const useDeleteJobMailingPermissionsMutation = ({
  onSuccess,
  onError,
  options = {},
} = {}) =>
  useMutation(
    [JOB_QUERY_KEYS.deleteJobMailingPermissions],
    ({ jobId, data }) =>
      deleteJobMailingPermissionApi({
        jobId,
        data,
      }),
    {
      onSuccess: (data) => {
        if (onSuccess && typeof onSuccess === 'function') {
          onSuccess(data);
        }
      },
      onError: (error) => {
        if (onError && typeof onError === 'function') {
          onError(error);
        }
      },
      ...options,
    },
  );

// === JOB MAILING PERMISSIONS END === //

// === JOB TITLES START === //
const getJobsTitles = () =>
  pythonAxios({
    method: 'GET',
    url: '/jobs/titles',
  }).then(({ data }) => data.results);

export const useJobTitles = ({ options = {} } = {}) =>
  useQuery([JOB_QUERY_KEYS.jobTitles], getJobsTitles, {
    ...options,
    onError: (error) => {
      toast.error('There was an error while fetching jobs list', {
        id: 'FETCH_JOBS_LIST',
        statusCode: error?.response?.status,
      });

      if (options.onError) {
        options.onError(error);
      }
    },
  });
// === JOB TITLES END === //

// === JOB SEARCH TITLES AI SUGGESTION START === //
const getAISearchTitles = ({ id, language }) =>
  pythonAxios({
    method: 'GET',
    url: `jobs/${id}/predict/search-titles`,
    params: {
      language,
    },
  }).then(({ data }) => data.result);

export const useAISearchTitles = ({ id, language, options = {} } = {}) =>
  useQuery(
    [JOB_QUERY_KEYS.aiTitleSuggestion, id],
    () => getAISearchTitles({ id, language }),
    {
      ...options,
      onError: (error) => {
        toast.error(
          'There was an error while fetching search title suggestions',
          {
            id: 'FETCH_AI_TITLES_SUGGESTIONS',
            statusCode: error?.response?.status,
          },
        );

        if (options.onError) {
          options.onError(error);
        }
      },
    },
  );
// === JOB SEARCH TITLES AI SUGGESTION END === //

// TEMPORARY
export const useInvalidateDashboardJobs = () => {
  const queryClient = useQueryClient();

  return () => {
    queryClient.invalidateQueries(JOB_QUERY_KEYS.dashboardJobs);
  };
};
