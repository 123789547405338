import React from 'react';
import { useParams } from 'react-router-dom';
import { Grid, Label, SelectV2 } from '@lionstep/ui';

// Queries
import { useScreeningQuestionsQuery } from '../../../../../../queries/jobQueries';

// Styles
import styles from './screeningQuestions.module.scss';

const SCREENING_QUESTION_TYPES = {
  SINGLE_SELECT: 'Single select',
  TEXT: 'Free text',
  NUMBER: 'Number',
  FILE: 'File',
  BOOLEAN: 'Boolean',
  DATE: 'Date',
  MULTI_SELECT: 'Multi select',
  INFORMATION: 'Information',
};

const Question = ({ question, index }) => (
  <Grid.Col xs={24}>
    <Label bold>Question {index + 1}</Label>
    <p className={styles.question}>{question.title}</p>
  </Grid.Col>
);

const QuestionAnswers = ({ question }) => (
  <>
    <Grid.Col xs={24}>
      <Label bold>Answers</Label>
    </Grid.Col>
    <Grid.Col xs={24}>
      <SelectV2
        className={styles.select}
        placeholder={`${question.format.options.length} options`}
        options={question.format.options.map((option) => ({
          value: option.id,
          label: option.name,
          key: option.id,
          unselectable: true,
        }))}
      />
    </Grid.Col>
  </>
);

const QuestionType = ({ question }) => (
  <Grid.Col xs={8}>
    <Label bold>Type of question</Label>
    <p className={styles.question}>
      {SCREENING_QUESTION_TYPES[question.format.type] || 'Unknown'}
    </p>
  </Grid.Col>
);

const QuestionRequired = ({ question }) => (
  <Grid.Col xs={8}>
    <Label bold>Required</Label>
    <p className={styles.question}>{question.required ? 'Yes' : 'No'}</p>
  </Grid.Col>
);

const QuestionDependent = ({ question }) => (
  <Grid.Col xs={8}>
    <Label bold>Dependent on other question</Label>
    <p className={styles.question}>
      {question.precondition_question_id ? 'Yes' : 'No'}
    </p>
  </Grid.Col>
);

const ScreeningQuestions = React.forwardRef((props, ref) => {
  const params = useParams();

  const { data } = useScreeningQuestionsQuery({
    id: params.id,
    options: { enabled: false },
  });

  return (
    <div ref={ref}>
      {data?.map((question, index) => (
        <Grid.Row key={question.id} className={styles.row}>
          {/* Question */}
          <Question question={question} index={index} />
          {/* Question Answers */}
          {question.format?.options?.length && (
            <QuestionAnswers question={question} />
          )}
          {/* Question Type */}
          <QuestionType question={question} />

          {/* Question Required */}
          <QuestionRequired question={question} />

          {/* Question Dependent */}
          <QuestionDependent question={question} />
        </Grid.Row>
      ))}
    </div>
  );
});

export default ScreeningQuestions;
