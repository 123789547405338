import React, { useState, useEffect, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import { Link, useLocation, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames/bind';
import { Icon, Spinner } from '@lionstep/ui';
import { useTranslation } from 'react-i18next';

// Actions
import {
  getJob,
  getJobExtraInfo,
  resetJob,
} from '../../redux/actions/job.actions';

// Selectors
import {
  jobSelector,
  extraInfoSelector,
  isLoadingJobSelector,
  isLoadingExtraInfoSelector,
} from '../../redux/selectors/job.selectors';

// Hooks
import useSearchParams from '../../../../hooks/useSearchParams';
import useIsLionstepAdmin from '../../../../hooks/useIsLionstepAdmin';

// Helpers
import { getActiveItem, getActiveSubItem } from './jobLayout.helpers';

// Styles
import styles from './jobLayout.module.scss';

const cx = classNames.bind(styles);

const SIDE_MENU_CREATE = {
  label: 'create_job',
  path: () => '/create-job',
  key: 'createJob',
  icon: 'Edit',
  render: true,
  submenu: [
    {
      label: 'job_description_page.general_section.title',
      key: 'general',
    },
    {
      label: 'job_description_page.summary_section.title',
      key: 'summary',
    },
    {
      label: 'job_description_page.team_section.title',
      key: 'team',
    },
    {
      label: 'job_description_page.meta_section.title',
      key: 'meta',
    },
  ],
};

const SIDE_MENU_DUPLICATE = {
  ...SIDE_MENU_CREATE,
  label: 'duplicate_job_page.title',
  key: 'duplicateJob',
  path: (id) => `/duplicate-job/${id}`,
};

const SIDE_MENU = [
  {
    label: 'edit_job',
    path: (id) => `/edit-job/${id}`,
    key: 'editJob',
    icon: 'Edit',
    render: true,
    submenu: [
      {
        label: 'job_description_page.general_section.title',
        key: 'general',
      },
      {
        label: 'job_description_page.summary_section.title',
        key: 'summary',
      },
      {
        label: 'job_description_page.team_section.title',
        key: 'team',
      },
      {
        label: 'job_description_page.meta_section.title',
        key: 'meta',
      },
    ],
  },

  {
    label: 'Admin Actions',
    path: (id) => `/edit-job/${id}/admin`,
    key: 'adminActions',
    icon: 'Shield',
    render: false,
    submenu: [
      {
        label: 'job_settings',
        key: 'jobSettings',
      },
      {
        label: 'killer_questions.title',
        key: 'killerQuestions',
      },
      {
        label: 'optional_questions.title',
        key: 'optionalQuestions',
      },
      {
        label: 'Screening Questions',
        key: 'screeningQuestions',
      },
    ],
  },

  {
    label: 'Insights',
    path: (id) => `/edit-job/${id}/insights`,
    key: 'insights',
    icon: 'Insights',
    render: true,
  },
  {
    label: 'Candidates',
    path: (id) => `/edit-job/${id}/candidates`,
    key: 'candidates',
    icon: 'User',
    render: true,
  },
  {
    label: 'Analytics',
    path: (id) => `/edit-job/${id}/analytics`,
    key: 'analytics',
    icon: 'Analytics',
    render: false,
  },
];

const MainMenu = ({ activeItem, activeSubItem, setActiveSubItem }) => {
  const { t } = useTranslation();
  const { id } = useParams();
  const { pathname } = useLocation();
  const isLionstepAdmin = useIsLionstepAdmin();

  /* === SCROLL UP AND DOWN LOGIC START === */
  let prevScrollpos = window.pageYOffset;

  const handlePageScroll = () => {
    const currentScrollPos = window.pageYOffset;

    if (window.innerWidth < 992) {
      if (prevScrollpos > currentScrollPos || currentScrollPos < 110) {
        document
          .getElementById('sideMenu')
          .classList.add(styles.animateTopPositive);
        document
          .getElementById('sideMenu')
          .classList.remove(styles.animateTopNegative);
      } else {
        document
          .getElementById('sideMenu')
          .classList.add(styles.animateTopNegative);
        document
          .getElementById('sideMenu')
          .classList.remove(styles.animateTopPositive);
      }
      prevScrollpos = currentScrollPos;
    }
  };

  const handleWindowResize = () => {
    if (window.innerWidth >= 992) {
      const sideMenuEl = document.getElementById('sideMenu');
      sideMenuEl.classList.remove(styles.animateTopPositive);
      sideMenuEl.classList.remove(styles.animateTopNegative);
    }
  };

  useLayoutEffect(() => {
    window.addEventListener('scroll', handlePageScroll);
    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('scroll', handlePageScroll);
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);
  /* === SCROLL UP AND DOWN LOGIC END === */

  const isCreateJobPage = pathname === '/create-job';
  const isDuplicateJobPage = pathname.startsWith('/duplicate-job');

  const handleOnEditJobSubMenuClick = (item) => {
    const element = document.getElementById(item.key);
    const topOffset =
      element.getBoundingClientRect().top + window.pageYOffset - 120;

    window.scrollTo({ top: topOffset, behavior: 'smooth' });
    setActiveSubItem(item.key);
  };

  const renderEditJobMenuSubItems = (item) => (
    <span
      className={cx(styles.submenuItem, {
        [styles.submenuItemActive]: item.key === activeSubItem,
      })}
      key={item.key}
      onClick={() => handleOnEditJobSubMenuClick(item)}
    >
      {t(item.label)}
    </span>
  );

  const renderSubMenu = (item) => (
    <div className={styles.submenu}>
      {item.submenu.map((subitem) => {
        if (
          item.key === 'createJob' ||
          item.key === 'duplicateJob' ||
          item.key === 'editJob' ||
          item.key === 'adminActions'
        ) {
          return renderEditJobMenuSubItems(subitem);
        }

        return null;
      })}
    </div>
  );

  const renderMenuItem = (item) => (
    <div
      key={item.key}
      className={cx(styles.menuItem, {
        [styles.menuItemActive]: item.key === activeItem,
      })}
    >
      <Link className={styles.menuItemLink} key={item.key} to={item.path(id)}>
        <Icon className={styles.menuItemIcon} name={item.icon} />
        <span className={styles.menuItemLabel}>{t(item.label)}</span>
      </Link>
      {item.submenu && item.key === activeItem && (
        <div className={styles.desktopSubmenu}>{renderSubMenu(item)}</div>
      )}
    </div>
  );

  return (
    <div className={styles.menuWrapper}>
      {isCreateJobPage || isDuplicateJobPage ? (
        <>
          <div className={styles.menu}>
            {renderMenuItem(
              isCreateJobPage ? SIDE_MENU_CREATE : SIDE_MENU_DUPLICATE,
            )}
          </div>
          <div className={styles.mobileSubmenu}>
            {renderSubMenu(
              isCreateJobPage ? SIDE_MENU_CREATE : SIDE_MENU_DUPLICATE,
            )}
          </div>
        </>
      ) : (
        <>
          <div className={styles.menu}>
            {SIDE_MENU.map((item) =>
              isLionstepAdmin || item.render ? renderMenuItem(item) : null,
            )}
          </div>
          {/**
           * TODO: Fix this, should not render it if it's not mobile screen
           * This way, we render both menus and same items in different menus has same KEYs
           *  */}
          {SIDE_MENU.map((item) =>
            (isLionstepAdmin || item.render) &&
            item.submenu &&
            item.key === activeItem ? (
              <div className={styles.mobileSubmenu} key={item.key}>
                {renderSubMenu(item)}
              </div>
            ) : null,
          )}
        </>
      )}
    </div>
  );
};

MainMenu.propTypes = {
  activeItem: PropTypes.string.isRequired,
  activeSubItem: PropTypes.string.isRequired,
  setActiveSubItem: PropTypes.func.isRequired,
};

const JobLayout = ({ children }) => {
  const { pathname } = useLocation();
  const { params } = useSearchParams();
  const { type: keyPointsType } = params;
  const { id } = useParams();
  const [activeItem, setActiveItem] = useState(() => getActiveItem(pathname));
  const [activeSubItem, setActiveSubItem] = useState(() =>
    getActiveSubItem(pathname, keyPointsType),
  );
  const dispatch = useDispatch();
  const job = useSelector(jobSelector);
  const extraInfo = useSelector(extraInfoSelector);
  const isLoadingJob = useSelector(isLoadingJobSelector);
  const isLoadingExtraInfo = useSelector(isLoadingExtraInfoSelector);

  useEffect(() => {
    if (id && !pathname.includes(`/edit-job/${id}/candidates`)) {
      dispatch(getJob(id));
      dispatch(getJobExtraInfo(id));
    }

    return () => dispatch(resetJob());
  }, []);

  useEffect(() => {
    if (id && !pathname.includes(`/edit-job/${id}/candidates`)) {
      dispatch(getJob(id));
      dispatch(getJobExtraInfo(id));
    }

    setActiveItem(getActiveItem(pathname));
  }, [pathname]);

  useEffect(() => {
    setActiveSubItem(getActiveSubItem(pathname, keyPointsType));
  }, [keyPointsType]);

  return (
    <div className={styles.layout}>
      <div id="sideMenu" className={styles.sideMenu}>
        {/* Side Menu */}
        <MainMenu
          activeItem={activeItem}
          activeSubItem={activeSubItem}
          setActiveSubItem={setActiveSubItem}
        />
      </div>
      <div
        className={cx(styles.contentWrapper, {
          [styles.contentWrapperNoSubmenu]: ![
            ...SIDE_MENU,
            SIDE_MENU_CREATE,
          ].find((item) => item.key === activeItem)?.submenu,
        })}
      >
        {(!job || !extraInfo) && (isLoadingJob || isLoadingExtraInfo) && (
          <Spinner position="fixed" size={100} />
        )}
        {((job && extraInfo) ||
          pathname === '/create-job' ||
          pathname.includes(`/edit-job/${id}/candidates`)) && (
          <div className={styles.content}>{children}</div>
        )}
      </div>
    </div>
  );
};

JobLayout.defaultProps = {
  children: null,
};

JobLayout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

export default JobLayout;
