import { toast } from '@lionstep/ui';

import {
  getAccountManagersApi,
  getQuestionsApi,
  postQuestionsApi,
} from '../../../../api/job.api';
import { getCitiesApi } from '../../../../api/location.api';
import { getAllSkillsApi } from '../../../../api/es.api';

// types
import * as types from '../types/adminActions.types';

export const getAccountManagers = () => async (dispatch) => {
  try {
    dispatch({ type: types.GET_ACCOUNT_MANAGERS_START });

    const { data } = await getAccountManagersApi();

    dispatch({
      type: types.GET_ACCOUNT_MANAGERS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({ type: types.GET_ACCOUNT_MANAGERS_FAIL });
    toast.error('Error fetching account managers', {
      id: types.GET_ACCOUNT_MANAGERS_FAIL,
      statusCode: error?.response?.status,
    });
  }
};

export const getCities =
  (value, language = 'en') =>
  async (dispatch) => {
    try {
      dispatch({ type: types.GET_CITIES_START });

      const { data } = await getCitiesApi({
        params: {
          input: value,
          language,
        },
      });

      dispatch({
        type: types.GET_CITIES_SUCCESS,
        payload: data.predictions,
      });
    } catch (error) {
      dispatch({ type: types.GET_CITIES_FAIL });
      toast.error('Error fetching locations', {
        id: types.GET_CITIES_FAIL,
        statusCode: error?.response?.status,
      });
    }
  };

export const clearCities = () => (dispatch) =>
  dispatch({ type: types.CLEAR_CITIES });

/**
 * KILLER QUESTIONS
 */
export const getKillerQuestions = (jobId) => async (dispatch) => {
  try {
    dispatch({ type: types.GET_KILLER_QUESTIONS_START });

    const response = await getQuestionsApi({
      id: jobId,
      questionType: 'killer_questions',
    });

    dispatch({
      type: types.GET_KILLER_QUESTIONS_SUCCESS,
      payload: response.data.questions,
    });
  } catch (error) {
    dispatch({ type: types.GET_KILLER_QUESTIONS_FAIL });

    toast.error('Error loading killer questions', {
      id: types.GET_KILLER_QUESTIONS_FAIL,
      statusCode: error?.response?.status,
    });
  }
};

export const updateKillerQuestions =
  ({ id, data }) =>
  async (dispatch) => {
    try {
      dispatch({ type: types.UPDATE_KILLER_QUESTIONS_START });

      const response = await postQuestionsApi({
        id,
        questionType: 'killer_questions',
        data: { questions: data },
      });

      dispatch({
        type: types.UPDATE_KILLER_QUESTIONS_SUCCESS,
        payload: response.data.questions,
      });
    } catch (error) {
      dispatch({ type: types.UPDATE_KILLER_QUESTIONS_FAIL });

      toast.error('Error saving killer questions', {
        id: types.GET_KILLER_QUESTIONS_FAIL,
        statusCode: error?.response?.status,
      });
    }
  };

/**
 * SKILLS
 */
export const getAllSkills = (value) => async (dispatch) => {
  try {
    dispatch({ type: types.GET_SKILLS_START });

    const response = await getAllSkillsApi({ value });

    dispatch({
      type: types.GET_SKILLS_SUCCESS,
      payload: response.data.results,
    });
  } catch (error) {
    dispatch({ type: types.GET_SKILLS_FAIL });

    toast.error('Error loading skills', {
      id: types.GET_SKILLS_FAIL,
      statusCode: error?.response?.status,
    });
  }
};

export const clearAllSkills = () => ({ type: types.CLEAR_ALL_SKILLS });
